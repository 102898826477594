import { render, staticRenderFns } from "./TypeDocumentResidency.vue?vue&type=template&id=486e8527&scoped=true&"
import script from "./TypeDocumentResidency.ts?vue&type=script&lang=ts&"
export * from "./TypeDocumentResidency.ts?vue&type=script&lang=ts&"
import style0 from "./TypeDocumentResidency.vue?vue&type=style&index=0&id=486e8527&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "486e8527",
  null
  
)

export default component.exports